import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import Packery from 'packery';
import vco from "v-click-outside";



// Config
const config = require('@/config/config.json');

import axios  from './plugins/axios'
Vue.use(axios, { baseURL: config.baseUrl })

// Initialize Firebase
import { initializeApp } from "firebase/app";
initializeApp(config.firebase);

Vue.use(vco);
Vue.config.productionTip = false;

// Vuetify Object (as described in the Vuetify 2 documentation)
const vuetify = new Vuetify();

// use Vuetify's plugin
Vue.use(Vuetify)
// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
