<template>
  <div ref="grid">
    <v-overlay :value="$store.state.overlay" style="z-index: 209"></v-overlay>
    <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        v-if="$store.state.progress"
        class="grid-loader"
        style="z-index: 210"
    ></v-progress-circular>

<!--    <button @click="addItem">Add an item dynamically</button>-->
<!--    <input type="checkbox" v-model="draggable" /> Draggable-->
<!--    <input type="checkbox" v-model="resizable" /> Resizable-->
<!--    <input type="checkbox" v-model="responsive" /> Responsive-->
<!--    <v-icon aria-hidden="false" tag="i">-->
<!--      mdi-folder-outline-->
<!--    </v-icon>-->
<!--    <v-icon aria-label="My Account" role="img" aria-hidden="false">-->
<!--      {{icons.mdiAccount}}-->
<!--    </v-icon>-->
<!--    <svg-icon type="mdi" :path="icons.mdiAccount"></svg-icon>-->


    <v-breadcrumbs :items="collectionPath" divider="/"></v-breadcrumbs>
    <v-btn class="mx-2" fab dark large color="green" fixed right bottom @click="showPinModal(0, $event)"><v-icon dark>mdi-plus</v-icon></v-btn>
    <pin-modal @update:save="handleSave" @update:close="removeItem(index - 1, $event)" @update:visible="isDialogVisible = $event" :visible="isDialogVisible" :pin="pin" :pin-index="pinIndex"></pin-modal>
    <grid-layout :layout.sync="layout"
                 :col-num="colNum"
                 :row-height="rowHeight"
                 :is-draggable="draggable"
                 :is-resizable="resizable"
                 :responsive="responsive"
                 :vertical-compact="true"
                 :use-css-transforms="true"
                 :breakpoints="breakpoints"
                 :cols="cols"
    >
      <grid-item v-for="(item, itemKey) in layout"
                 :static="item.static"
                 :x="item.x"
                 :y="item.y"
                 :w="item.w"
                 :h="item.h"
                 :i="item.i"
                 :key="item.i"
                 @move="moveEvent"
                 @moved="movedEvent"
                 @resized="resizedEvent"
                 style="background-size: cover; background-position: center"
                 :class="item.type"
      >
<!--        :style="{ backgroundImage: 'url(' + item.imgSrc + ')' }"-->
        <div style="width: 100%; height: 100%"
             @click="pinClick(itemKey)"
             @dblclick="pinClick(itemKey)"
             @mousedown="mouseDown(itemKey)">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              v-if="!loaded[itemKey]"
              class="item-loader"
          ></v-progress-circular>
          <div class="actions">
            <v-menu :close-on-content-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="1" icon color="white" small v-bind="attrs" v-on="on">
<!--                  <svg fill="#000000" viewBox="-28.16 -28.16 312.32 312.32" id="Flat" xmlns="http://www.w3.org/2000/svg" style="&#45;&#45;darkreader-inline-fill: #000000; &#45;&#45;darkreader-inline-stroke: #e8e6e3;" data-darkreader-inline-fill="" stroke="#000000" stroke-width="3.5840000000000005" data-darkreader-inline-stroke="" transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#ffffff" stroke-width="40.448" style="&#45;&#45;darkreader-inline-stroke: #c8c3bc;" data-darkreader-inline-stroke=""> <path d="M128,100a28,28,0,1,0,28,28A28.03146,28.03146,0,0,0,128,100Zm0,48a20,20,0,1,1,20-20A20.02229,20.02229,0,0,1,128,148Zm0-72a28,28,0,1,0-28-28A28.03146,28.03146,0,0,0,128,76Zm0-48a20,20,0,1,1-20,20A20.02229,20.02229,0,0,1,128,28Zm0,152a28,28,0,1,0,28,28A28.03146,28.03146,0,0,0,128,180Zm0,48a20,20,0,1,1,20-20A20.02229,20.02229,0,0,1,128,228Z"></path> </g><g id="SVGRepo_iconCarrier"> <path d="M128,100a28,28,0,1,0,28,28A28.03146,28.03146,0,0,0,128,100Zm0,48a20,20,0,1,1,20-20A20.02229,20.02229,0,0,1,128,148Zm0-72a28,28,0,1,0-28-28A28.03146,28.03146,0,0,0,128,76Zm0-48a20,20,0,1,1-20,20A20.02229,20.02229,0,0,1,128,28Zm0,152a28,28,0,1,0,28,28A28.03146,28.03146,0,0,0,128,180Zm0,48a20,20,0,1,1,20-20A20.02229,20.02229,0,0,1,128,228Z"></path> </g></svg>-->
<!--                  <svg fill="#ffffff" viewBox="0 0 256.00 256.00" id="Flat" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="0.00256"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#000000" stroke-width="15.872"> <path d="M156,128a28,28,0,1,1-28-28A28.02769,28.02769,0,0,1,156,128ZM128,76a28,28,0,1,0-28-28A28.02769,28.02769,0,0,0,128,76Zm0,104a28,28,0,1,0,28,28A28.02769,28.02769,0,0,0,128,180Z"></path> </g><g id="SVGRepo_iconCarrier"> <path d="M156,128a28,28,0,1,1-28-28A28.02769,28.02769,0,0,1,156,128ZM128,76a28,28,0,1,0-28-28A28.02769,28.02769,0,0,0,128,76Zm0,104a28,28,0,1,0,28,28A28.02769,28.02769,0,0,0,128,180Z"></path> </g></svg>-->


                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <circle cx="50" cy="20" r="8" fill="white" stroke="black" stroke-width="3"/>
                    <circle cx="50" cy="50" r="8" fill="white" stroke="black" stroke-width="3"/>
                    <circle cx="50" cy="80" r="8" fill="white" stroke="black" stroke-width="3"/>
                  </svg>


                </v-btn>
              </template>

              <v-list>
                <v-list-item link>
                  <v-list-item-title>✅ Mark as done</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title><span @click="showPinModal(item.i, $event)">✏️ Edit</span></v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title><span @click="removeItem(item.i, $event)">❌ Delete</span></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>


          </div>

          <div v-if="!item.edit" :class="hasImage(itemKey) ? 'title' : 'title-top'" >{{item.title}}</div>
          <div v-if="item.edit" :class="hasImage(itemKey) ? 'title' : 'title-top'"><input @focusout="closeEdit(item.i)"  @keyup.enter="closeEdit(item.i)" v-model="item.title" type="text" placeholder="Title"></div>

          <v-img v-if="item.imgSrc" :src="getImgSrc(item)" :lazy-src="item.tempImgSrc" alt="" @load="onImgLoad(itemKey)"></v-img>
          <svg v-if="item.type === 'folder'" class="blue--text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path :d="icons.mdiFolder" fill="currentColor"></path></svg>

          <div v-if="!item.edit" class="description" v-html="item.desc"></div>
          <div v-if="item.edit" v-model="item.desc" class="description">
            <textarea  v-model="item.desc" @focusout="closeEdit(item.i)"  @keyup.enter="closeEdit(item.i)"></textarea>
          </div>
          <!--                 -->

          <!--        <span class="text">{{item.i}}</span>-->
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>



<script>


import { GridLayout, GridItem } from "vue-grid-layout";

import Vue from 'vue';
import PinModal from "@/components/PinModal.vue";
import axios from '@/axios';
import { mdiAccount, mdiFolderOutline, mdiFolder } from "@mdi/js";
import SvgIcon from '@jamescoyle/vue-icon';
import folderIcon from '@/assets/folder-outline.svg'
const config = require('@/config/config.json');


// https://image.tmdb.org/t/p/w300_and_h450_bestv2/uDsvma9dAwnDPVuCFi99YpWvBk0.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/tKjH1IQVeUmHOlxZinQdjFHkFVG.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/xLal6fXNtiJN6Zw6qk21xAtdOeN.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/dzN5xbp73TI7pjEqZ66ECNxdWJl.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/imBsBkySpNJ4eXZxpRnbfSl2mwn.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/azL2ThbJMIkts3ZMt3j1YgBUeDB.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/mUaSjomGtcVFbOwUgI1xJa5Fgqu.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/fjWiVF2vFwd3mhs1dNoS0SOHxqt.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/zGAUIcWOufCg0dtju7Lr6XKpGS9.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/5xSB0Npkc9Fd9kahKBsq9P4Cdzp.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/rSmTqtb7gICeRwvsEmq0uJxR4XU.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/ipJ4mgqse6uoTRsDyU3TXmva1rt.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/v4dq1MWDe4gZ5BjRCkHBOi76KI0.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/euVaCiCWz3AALcQXHT6aUqdGUo6.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/azokJEYd6ZYaUiNUSPlPBzFQzs0.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/shfAU6xIIEAEtsloIT3n9Fscz2E.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/5diuxeLN5pbD9tjp9T73dn4tz8m.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/uJDtI8RZPbV4gn5cEyxPyXF7QJo.jpg
// https://image.tmdb.org/t/p/w300_and_h450_bestv2/qj3S8lkzehYv3ZPxjZjbyUi5Ucg.jpg
export default {
  name: "VueGridLayout",
  components: {
    PinModal,
    GridLayout,
    GridItem,
    SvgIcon
  },


  data() {
    return {
      icons: {
        mdiAccount,
        mdiFolderOutline,
        mdiFolder,
        folderIcon
      },
      layout: [],
      loaded: [],
      wasMoved: [],
      collectionId: null,
      collectionPath: [],
      draggable: true,
      resizable: true,
      responsive: true,
      colNum: 12,
      index: 0,
      isDialogVisible: false,
      rowHeight: 100,
      breakpoints: { xlg: 1400, lg: 1200, md: 996, sm: 768, xs: 576, xxs: 0 },
      cols: { xlg: 14, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
      pin: {
        x:8,
        y:2,
        w:2,
        h:2,
        i:12,
        edit: false,
        title: "",
        desc: "",
        imgSrc:"",
        link: "",
        type: null
      },
      pinIndex: null,
      progress: false,
      overlay: false,
      mouseClickTime: null
    }
  },
  created() {
    console.log('Created');
    // this.$gridlayout.load();
    this.loadPins();

    let lastIndex = 1;
    for (let i = 0; i < this.layout.length; i++) {
      let currentIndex = this.layout[i];
      if (typeof currentIndex === 'string' || currentIndex instanceof String) {
        currentIndex = parseInt(currentIndex);
      }

      lastIndex = this.layout[i].i > lastIndex ? this.layout[i].i : lastIndex;
    }
    this.index = lastIndex + 1;
  },
  mounted() {
    // console.log(this.$refs.grid.clientWidth);
    console.log('Mounted');
    this.rowHeight = this.calcRowHeight();
    window.addEventListener('resize', this.calcRowHeight);

  },
  unmounted() {
    console.log('Unmounted');
    window.removeEventListener('resize', this.getDimensions);
  },
  beforeRouteEnter (to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.

    console.log('beforeRouteEnter');
    const id = to.params.id
    next()
  },
  beforeRouteUpdate (to, from, next) {
    console.log('beforeRouteUpdate');
      next()
  },
  watch: {
    '$route'(to, from) {
      const id = to.params.id

      this.layout = [];
      this.loadPins(id);

      let lastIndex = 1;
      for (let i = 0; i < this.layout.length; i++) {
        let currentIndex = this.layout[i];
        if (typeof currentIndex === 'string' || currentIndex instanceof String) {
          currentIndex = parseInt(currentIndex);
        }

        lastIndex = this.layout[i].i > lastIndex ? this.layout[i].i : lastIndex;
      }
      this.index = lastIndex + 1;
    }
  },
  methods: {
    showPinModal(val, event) {
      if (event) {
        event.stopPropagation();
      }
      if (!val) {
        this.pinIndex = this.index;
        val = this.addItem();
      }
      const index = this.layout.map(item => item.i).indexOf(val);
      this.pin = this.layout[index];
      this.pinIndex = index;

      this.isDialogVisible = true;
    },
    getImgSrc(pin) {
      let imgPath = pin.imgSrc;
      if (pin.type === 'folder') {
        return this.icons.folderIcon;
      }

      if (imgPath.indexOf('http') !== 0 && imgPath.indexOf('//') !== 0 && imgPath.indexOf('data:') !== 0) {
        return config.pinsImagesFolder + '/' + this.collectionId + '/' + imgPath;
      }

      return imgPath;
    },
    closeTimerDialog() {
      this.isDialogVisible = false;
    },
    addItem: function () {
      // Add a new item. It must have a unique key!
      this.loaded.push(false);
      this.layout.push({
        x: (this.layout.length * 2) % (this.colNum || 12),
        y: this.layout.length + (this.colNum || 12), // puts it at the bottom
        // x: 0,
        // y: 0,
        w: 2,
        h: 2,
        i: this.index,
        edit: false,
        title: null,
        desc: null,
        link: null,
        imgSrc: null
      });
      // Increment the counter to ensure key is always unique.
      return this.index++;
    },
    removeItem: function (val, event) {
      if (event) {
        event.stopPropagation();
      }

      const index = this.layout.map(item => item.i).indexOf(val);

      if (typeof val == 'string') {
        const response = this.$axios.delete('/api/collection/' + this.collectionId + '/pin/' + val);
      }

      this.loaded.splice(index, 1);
      this.layout.splice(index, 1);
    },
    editItem: function (val) {
      const index = this.layout.map(item => item.i).indexOf(val)
      let item = this.layout[index];
      item.edit = true;
      Vue.set(this.layout, index, item);
    },
    closeEdit: function (val) {
      const index = this.layout.map(item => item.i).indexOf(val)
      let item = this.layout[index];
      item.edit = false;
      Vue.set(this.layout, index, item);
      this.saveLayout();
      return true;
    },
    moveEvent: function(i, newX, newY){
      // console.log("MOVE i=" + i + ", X=" + newX + ", Y=" + newY);
      // this.saveLayout();
    },
    movedEvent: function(i, newX, newY){
      const index = this.layout.map(item => item.i).indexOf(i)
      this.wasMoved[index] = true;
      console.log("MOVED i=" + i + ", X=" + newX + ", Y=" + newY);
      localStorage.setItem( 'layout', JSON.stringify( this.layout ) );
      this.saveLayout();
    },
    resizeEvent: function(i, newH, newW, newHPx, newWPx){
      console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx);
      this.saveLayout();
    },
    resizedEvent: function(i, newH, newW, newHPx, newWPx){
      console.log("RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx);
      this.saveLayout();
    },
    saveLayout: function () {
      let collectionId = '';
      if (typeof this.$route.params.id !== 'undefined') {
        collectionId = this.$route.params.id;
      }

      const response = this.$axios.post('/api/collection/' + collectionId, this.layout);
      localStorage.setItem( 'layout', JSON.stringify( this.layout ) );
    },
    async handleSave(data) {
      this.$store.state.progress = true;
      this.$store.state.overlay = true;
      let tempPinData = this.layout[data.index];
      tempPinData.title = data.form.title;
      tempPinData.link = data.form.link;
      tempPinData.desc = data.form.desc;
      tempPinData.type = data.form.type;
      tempPinData.w = data.form.width;
      tempPinData.h = data.form.height;
      this.layout[data.index] = tempPinData;

      if (data.form.imgSrc) {
        tempPinData.imgSrc = data.form.imgSrc;
        // this.layout[data.index].imgSrc = tempPinData.imgSrc;
        this.layout[data.index].tempImgSrc = tempPinData.imgSrc;
      }

      let collectionId = null;
      if (typeof this.$route.params.id !== 'undefined') {
        collectionId = this.$route.params.id;
      }
      let postUrl = collectionId ? `/api/collection/${collectionId}/pin` : '/api/collection/pin';
      const response = await this.$axios.post(postUrl, tempPinData);
      this.loaded[data.index] = !data.form.imgSrc;
      console.log('Start image load ' + data.index);
      this.layout[data.index].i = response.data.id;
      this.layout[data.index].imgSrc = response.data.imgSrc;

      this.$store.state.progress = false;
      this.$store.state.overlay = false;
      // this.loadPins();

    },
    async loadPins(collectionId = null) {
      this.$store.state.progress = true;
      this.$store.state.overlay = true;

      if (typeof this.$route.params.id !== 'undefined' && !collectionId) {
        collectionId = this.$route.params.id;
      }

      let collectionUrl = collectionId ? '/api/collection/' + collectionId : '/api/collection';

      await axios.get(collectionUrl)
          .then(res => {
            for (let i = 0; i < res.data.items.length; i++) {
              this.loaded[i] = !res.data.items[i].imgSrc;
              this.wasMoved[i] = false;
            }
            this.$store.state.progress = false;
            this.$store.state.overlay = false;

            this.layout = res.data.items;
            this.collectionId = res.data.collection;

            // Breadcrumbs
            this.collectionPath = [];
            for (const collectionPathItem of res.data.collectionPath) {
              this.collectionPath.push({
                text: collectionPathItem.name,
                to: '/collection/' + collectionPathItem.id
              });
            }
          })
          .catch(err => {
            console.log(err)
          });
    },
    calcRowHeight() {
      let cols = 12;
      if (!this.$refs.grid) {
        return this.rowHeight;
      }
      let gridWith = this.$refs.grid.clientWidth;
      for (const colKey in this.breakpoints) {
        if (gridWith > this.breakpoints[colKey]) {
          cols = this.cols[colKey];
          break;
        }
      }

      this.rowHeight = (gridWith - (cols - 1) * 10) / cols;
      return this.rowHeight;
    },
    onImgLoad(itemKey) {
      // this.loaded[itemKey] = true;
      Vue.set(this.loaded, itemKey, true);
      // console.log('Image loaded ' + itemKey);
    },
    pinClick(pinKey) {
      // Wne item has been dragged and dropped
      if (this.wasMoved[pinKey]) {
        this.wasMoved[pinKey] = false;
        return null;
      }

      // When dragging and dropping in the same place
      if (this.mouseClickTime && window.performance.now() - this.mouseClickTime > 200) {
        return null;
      }

      console.log("Pin clicked");
      let pin = this.layout[pinKey];
      if (pin.type === 'folder') {
        console.log('Folder click');
        // this.$router.push({ name: 'collection', params: pin.i });
        this.$router.push({ path: `/collection/${pin.i}` });
      } else if (pin.link) {
        window.open(pin.link, '_blank');
      }
    },
    mouseDown(itemKey) {
      this.mouseClickTime = window.performance.now();
    },
    hasImage(itemKey) {
      return this.layout[itemKey].imgSrc
          || this.layout[itemKey].type === 'folder'
          ;
    }

  }
}
</script>

<style scoped>
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

/*************************************/

.actions {
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  text-align: right;
}

.vue-grid-layout {
  /*background: #eee;*/
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #E3F2FD;
  border-radius: 14px;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.vue-grid-item .v-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
  z-index: -1;
}

.vue-grid-item .title {
  font-size: 16px;
  text-align: center;
  position: absolute;
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  /*background-color: red;*/
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(117,117,117,0.8) 40%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.vue-grid-item .title-top {
  font-size: 16px;
  text-align: center;
  color: white;
  width: 100%;
}


.vue-grid-item input {
  border: 2px solid white !important;
  text-align: center;
}

.vue-grid-item {
  text-overflow: ellipsis;
  /*white-space: nowrap;*/
  overflow: hidden;
}

.vue-grid-item .description{
  padding: 10px;
}

.vue-grid-item .description textarea{
  width: 100%;
}

.reset-height {
  height: unset !important;
}

div .vue-grid-item.vue-grid-placeholder {
  background: green !important;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 10px;
}

.vue-grid-item > .vue-resizable-handle {
  /*background: unset !important;*/
  background-color: red !important;
}

.vue-grid-item > .vue-resizable-handle::before {
    content: "opa";
}

.vue-grid-item div.title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}
.v-progress-circular.grid-loader {
  z-index: 5;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.v-progress-circular.item-loader {
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.folder {
  border: 5px solid #2196f3;
}

.v-breadcrumbs {
  padding: 10px 0 0 10px;
}
</style>