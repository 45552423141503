<template>
  <div>
    <v-btn @click="addItem">Add</v-btn>
    <v-btn @click="removeItem">Remove</v-btn>
    <div class="grid" ref="grid">
      <div class="grid-sizer"></div>
      <div v-for="item in items" :data-item-id="item.id" class="grid-item" :class="item.class"></div>
    </div>
  </div>
</template>

<script>
import Packery from 'packery';
import Draggabilly from 'draggabilly';

export default {
  name: "Packery",
  data: () => ({
    packery: {},
    items: [
      {id: 1, "class": "grid-item--width2"},
      {id: 2, "class": "grid-item--height2"},
      {id: 3, "class": ""},
      {id: 4, "class": ""},
      {id: 5, "class": "grid-item--width2 grid-item--height2"},
      {id: 6, "class": "grid-item grid-item--width2"},
      {id: 7, "class": "grid-item--width2"},
      {id: 8, "class": "grid-item grid-item--height2"},
      {id: 9, "class": ""},
      {id: 10, "class": "grid-item--width2"},
      {id: 11, "class": "grid-item grid-item--height2"},
      {id: 12, "class": ""},
      {id: 13, "class": ""},
      {id: 14, "class": "grid-item--width2 grid-item--height2"},
      {id: 15, "class": ""},
      {id: 16, "class": "grid-item--width2"},
      {id: 17, "class": "grid-item--height2"},
      {id: 18, "class": ""},
    ],
  }),
  methods: {
    saveItemPositions() {
      // save drag positions
      var positions = this.packery.getShiftPositions('data-item-id');
      localStorage.setItem( 'dragPositions', JSON.stringify( positions ) );
      // console.log(positions)
    },
    addItem() {
      let length = this.items.length;
      let itemId = length + 1;
      this.items.push({id: itemId, "class": ""});
      // let newLemement = document.querySelector('[data-item-id="' + itemId + '"]');
      // console.log('[data-item-id="' + itemId + '"]');

      setTimeout(function() {
        // this.packery.appended(document.querySelector('[class="grid"]').lastChild);
        // console.log(document.querySelector('[class="grid"]').lastChild)
      }, 1000);
      // console.log(newLemement)

      // this.packery.appended(newLemement);
      // var draggie = new Draggabilly(newLemement);
      // this.packery.bindDraggabillyEvents(draggie);
      // this.packery.layout();
    },
    removeItem() {
      this.items.pop();
    }
  },
  mounted() {
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://unpkg.com/packery@2.1/dist/packery.pkgd.js')
    // document.head.appendChild(recaptchaScript)

    const grid = this.$refs.grid;
    this.packery = new Packery(grid, {
      itemSelector: '.grid-item',
      gutter: 10,
      columnWidth: '.grid-sizer',
      // percentPosition: true,
      // initLayout: false // disable initial layout
    });

    let packery = this.packery;

    // get saved dragged positions
    var initPositions = localStorage.getItem('dragPositions');
    // init layout with saved positions
    // this.packery.initShiftLayout(initPositions, 'data-item-id' );


    // Loop through each item and make it draggable
    packery.getItemElements().forEach( function( itemElem ) {
      var draggie = new Draggabilly(itemElem);
      packery.bindDraggabillyEvents(draggie);
    });


    // save drag positions on event
    this.packery.on('dragItemPositioned', this.saveItemPositions);
    //
    // this.packery.reloadItems();
    this.packery.layout();

  },
}
</script>

<style scoped>
* { box-sizing: border-box; }

body { font-family: sans-serif; }

/* ---- grid ---- */

.grid {
  background: #DDD;
  max-width: 1200px;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-item {
  /*padding-bottom: 25%; !* hack for proportional sizing *!*/
  float: left;
  width: 100px;
  height: 100px;
  background: #C09;
  border: 2px solid hsla(0, 0%, 0%, 0.5);
  border-radius: 10px;
}

.grid-sizer,
.grid-item {
  width: 100px;
}

.grid-item--width2 { width: 210px; }
.grid-item--height2 { height: 210px; }

.grid-item:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
  cursor: move;
}

.grid-item.is-dragging,
.grid-item.is-positioning-post-drag {
  background: #C90;
  z-index: 2;
}

.packery-drop-placeholder {
  outline: 3px dashed hsla(0, 0%, 0%, 0.5);
  outline-offset: -6px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
}

</style>