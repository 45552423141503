
  import Vue from 'vue'
  import Packery from "@/components/Packery.vue";
  import VueGridLayout from "@/components/VueGridLayout.vue";
  export default Vue.extend({
    name: 'Home',

    components: {
      VueGridLayout,
      Packery,
    },
    data: () => ({

    }),
    methods: {

    },
    mounted() {


    },

  })
