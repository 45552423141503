<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="1500">{{ snackbarText }}</v-snackbar>
    <v-dialog
        width="1024"
        v-model="dialogVisible"
        @click:outside="handleClickOutside"
    >
    <!-- Use the component in the right place of the template -->

      <v-card>
        <v-card-title class="text-center justify-center py-6">
          <h1 class="font-weight-bold text-h2">
            {{isEdit ? "Edit pin" : "Create new pin"}}
          </h1>
        </v-card-title>

      <v-container>
        <v-row v-if="!showForm">
          <v-col v-for="(pinType, index) in pinTypes">
            <v-card @click="choosePinType(index)" class="mx-auto" max-width="344" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">{{pinType.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{pinType.description}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <div v-if="showForm">
        <v-tabs
            @change="tabChange"
            v-model="tab"
            background-color="transparent"
            grow
        >
          <v-tab
              v-for="pinType in pinTypes"
              :key="pinType.title"
          >
            {{ pinType.title }}
          </v-tab>
        </v-tabs>

          <v-row>
            <v-col>
              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  v-if="showForm"
              >

                <!-- Title -->
                <v-text-field label="Title" v-if="isFormElementVisible('title')" v-model="form.title">
                  <v-icon slot="prepend">mdi-format-title</v-icon>
                </v-text-field>


                <!-- Link -->
                <v-text-field label="Link" v-if="isFormElementVisible('link')" v-model="form.link" @paste="handleLinkPaste">
                  <v-icon slot="prepend">mdi-link</v-icon>
                </v-text-field>

                <!-- Content -->
                <tiptap-vuetify v-model="form.desc" :extensions="extensions" v-if="isFormElementVisible('content')"/>

              </v-form>
            </v-col>
            <v-col v-if="isFormElementVisible('img')">
              <!-- Img -->
              <input type="file" ref="pinImgFile" style="display: none" @change="handleFileInput">

              <v-text-field label="Image" placeholder="Paste or click the icon" @paste="handleClipboardPaste" v-model="imgTitle">
                <v-icon @click="$refs.pinImgFile.click()" slot="prepend">mdi-image</v-icon>
              </v-text-field>

              <img :src="getImage" @load="imageLoaded" style="width: 300px" alt="">


            </v-col>
          </v-row>


        </div>

      </v-container>
      <v-divider></v-divider>
      <v-card-actions v-if="showForm">
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save">Save</v-btn>
      </v-card-actions>


      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import the component and the necessary extensions
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import { GridLayout, GridItem } from "vue-grid-layout";

export default {
  name: "PinModal",
  // specify TiptapVuetify component in "components"
  components: {
    TiptapVuetify,
    GridLayout,
    GridItem
  },
  props: {
    visible: false,
    pin: {
      type: Object,
      default: () => ({
        x:8,
        y:2,
        w:2,
        h:2,
        i:12,
        edit: false,
        title: null,
        desc: null,
        imgSrc: null,
        link: null,
        type: null
      }),
    },
    pinIndex: null
  },
  data: () => ({
    tab: null,
    valid: true,
    modalTitle: "Create new pin",
    isEdit: false,
    form: {
      title: null,
      link: null,
      desc: null,
      imgSrc: null,
      type: null,
      width: 2,
      height: 2,
    },
    imgTitle: null,
    showForm: false,
    pinTypes: [
      {
        type: "image",
        title: "Image Pin",
        description: "Paste or upload an image",
        visible: ['title', 'link', 'img']
      },
      {
        type: "link",
        title: "Link Pin",
        description: "Paste a link and an image and title will be loaded dynamically",
        visible: ['title', 'link', 'img']
      },
      {
        type: "content",
        title: "Content Pin",
        description: "Add text and format it by your needs",
        visible: ['content']
      },
      {
        type: "folder",
        title: "Folder",
        description: "Group your pins into a folder",
        visible: ['title']
      },
    ],
    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    // starting editor's content
    content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `,
    snackbar: false,
    snackbarText: "",
    supportedImageFiles: [
      'image/png',
      'image/jpeg',
      'image/gif',
      'image/bmp',
      'image/vnd.microsoft.icon',
      'image/tiff',
      'image/svg+xml',
      'image/webp',
      'image/avif',
      'image/apng',
      'image/x-icon'
    ],


  }),
  methods: {
    tabChange(value) {
      // this.selectedPinKey = value;
    },
    choosePinType(index) {
      this.tab = index;
      // this.form.type = this.pinTypes[index].type;
      this.showForm = true;
    },
    handleClipboardPaste(event) {
      console.log(event.clipboardData)
      if (event.clipboardData.files.length === 0) {
        this.imgTitle = null;
        this.displayError('Paste an image');
        event.preventDefault();
        return false;
      }

      let file = event.clipboardData.files[0];
      this.fileToBase64(file);
    },
    handleFileInput(event) {
      let file = event.target.files[0];
      // TODO add try catch and handle error
      this.fileToBase64(file);
    },
    fileToBase64(file) {
      console.log(file.type)
      if (!this.supportedImageFiles.includes(file.type)) {
        this.displayError('Paste an image');
      }

      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('loadend', this.fileLoaded);
      fr.addEventListener('error', this.errorHandler);
    },
    fileLoaded(e) {
      const fr = e.target;
      this.form.imgSrc = fr.result;

      let img = new Image();
      // img.addEventListener('load', this.imageLoaded);
      img.src = fr.result;
    },
    imageLoaded(e) {
      let img = e.target;
      let width = img.width;
      let height = img.height;

      if (!width || !height) {
        return;
      }

      if (width > height) {
        this.form.width = Math.round((width / height) * 2);
      } else {
        this.form.height = Math.round((height / width) * 2);
      }
      console.log(this.form.height)
      console.log(this.form.width)
    },
    errorHandler(e) {
      console.log('Error: ' + e.target.error.name);
      this.imgTitle = null;

    },
    async handleLinkPaste(event) {
      this.$store.state.progress = true;
      this.$store.state.overlay = true;

      console.log(event.clipboardData);
      let url = (event.clipboardData || window.clipboardData).getData("text");
      console.log(url);
      if (!url) {
        return null;
      }

      const response = await this.$axios.post('/api/fetch-link-info', {url: url});
      this.form.title = response.data.title;
      this.form.imgSrc = response.data.img;
      console.log(response.data);
      this.$store.state.progress = false;
      this.$store.state.overlay = false;
    },
    isFormElementVisible(elementName) {
      return this.pinTypes[this.tab].visible.includes(elementName);
    },
    save() {
      this.form.type = this.pinTypes[this.tab].type;
      this.dialogVisible = false;
      this.$emit('update:save', {form: this.form, index: this.pinIndex});
      console.log(JSON.stringify({form: this.form, index: this.pinIndex}));
    },
    close() {
      this.dialogVisible = false;
      if (!this.isEdit) {
        this.$emit('update:close');
      }
    },
    handleClickOutside(e) {
      if (!this.isEdit) {
        this.$emit('update:close');
      }
    },
    displayError(errorText) {
      this.snackbarText = errorText;
      this.snackbar = true;
    }
  },
  watch: {
    visible(newValue) {
      if (!newValue) {
        this.dialogVisible = false;
        console.log('Invisible');
        this.form = {
          title: null,
          link: null,
          desc: null,
          imgSrc: null,
          type: null,
          width: 2,
          height: 2,
        };
        this.showForm = false;
        this.tab = null;
        // this.form.type = null;
        this.isEdit = false;

      } else {
        console.log('Visible');
        this.form.title = this.pin.title;
        this.form.link = this.pin.link;
        this.form.desc = this.pin.desc;
        if (this.pin.type) {
          this.tab = this.pinTypes.map(item => item.type).indexOf(this.pin.type);
        } else {
          this.tab = 0;
        }

        if (this.pin.title || this.pin.link || this.pin.desc || this.pin.imgSrc) {
          this.showForm = true;
          // this.form.type = this.pinTypes[this.tab].type;
          this.isEdit = true;
        }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      }
    },
    getTitle() {
      return this.pin.title;
    },
    getImage() {
      if (!this.form.imgSrc) {
        return this.pin.imgSrc;
      }

       return this.form.imgSrc
    },
    pinType() {
     if (!this.tab) {
       return null;
     }

     return this.pinTypes[this.tab].type;
    }
  },
  mounted() {
    // console.log(`Mounted`)
  },
  beforeUpdate() {
    // console.log(`Before Update`)
  },
  updated() {
    // console.log(`Updated`)
  },
}
</script>

<style scoped>

</style>