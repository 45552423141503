import axios from 'axios'
import router from "@/router";

interface AxiosOptions {
    baseUrl?: string
    token?: string
}

export default {
    install: (Vue, options: AxiosOptions) => {
        Vue.prototype.$axios = axios.create({
            baseURL: options.baseUrl,
            headers: {
                Authorization: options.token ? `Bearer ${options.token}` : '',
            }
        })

        // TODO add working interceptor
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response.status === 401) {
                if (window.location.pathname !== '/login') {
                    router.push({ path: '/login' });
                }

                // retuen to login page
                // window.location.replace("/login");
            }
            return error;
        });
    }
}
