
import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    logout() {
      this.$axios.get('/api/auth/logout').then(res => {
        this.$store.state.isLoggedIn = false;
        this.$store.state.timerCardItems = [];
        this.$router.push({name: 'Login'});
      });
    },
  }
});
