import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from '@/axios';
import store from '@/store';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

  {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: HomeView
      },
      {
        path: 'collection/:id',
        name: 'Collection',
        component: HomeView
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/layouts/Login.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (store.state.isLoggedIn === null) {
    const response = await axios.get('/api/auth/isLoggedIn');
    if (response.data.isLoggedIn) {
      store.state.isLoggedIn = true;
    } else {
      store.state.isLoggedIn = false;
    }
  }

  if (store.state.isLoggedIn === false && to.name !== 'Login') {
    next({ name: 'Login' });
  }
  next()
})

router.beforeEach(async (to, from, next) => {
  if (store.state.isLoggedIn === true && to.name === 'Login') {
    next({ name: 'Home' });
  }
  next()
});



export default router
